/* .signIn-btn{
    background-color: #3C94E6;
    border-color: #3C94E6;
}
.signIn-btn:hover{
    background-color: #3C94E6;
    border-color: #3C94E6;
} */
a{
  text-decoration: none !important;
}
.orders-filter {
    display: flex !important;
    justify-content: space-between !important;
  }
  .orders-filter-border{
    border-radius: 8px;
    font-size: 14px;
  }
  .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    padding-top: 8px;
  }
  .form-group {
    margin-bottom: 0;
}
  .has-search .form-control {
      margin-bottom: 0;
    padding-left: 2.375rem;
  }
  .card-body h3 {
    color: black !important;
    padding-bottom: 22px !important;
    font-weight: 700;
    font-size: 22px !important;
    margin-bottom: 0 !important;
  }
  .card-body h2 {
    color: black !important;
    font-weight: 700;
    font-size: 22px !important;
    padding-bottom: 6px !important;
    margin-bottom: 0 !important;
  }
  .card-details {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    color: black;
    font-weight: 600;
    font-size: 16px;
  }
  .card-detail-p {
    font-size: 14px;
  }
  .card-bill {
    background: rgba(60, 121, 230, 0.13);
    border-radius: 6px;
    color: black;
    font-weight: 700;
    font-size: 14px;
    padding-left: 20px;
    margin-top: 42px;
    margin-bottom: 18px;
  }
  .card-amount {
    color: black;
    font-weight: 700;
    font-size: 18px;
  }
  .add-deal {
    background: #3C94E6 !important;
    border-radius: 4px !important;
    border-color: #3C94E6 !important;
    padding: 11px 20px !important;
    color: #fff;
  }
  .add-deal:hover {
    color: #fff;
  }
  .add-deal-img {
    padding-left: 6px;
    width: 17px;
    padding-bottom: 2px;
  }
  /* .add-banner-plan {
    font-size: 24px;
  } */
  .banner-plan-box{
    gap: 1.5rem;
  }
  .banner-plan-details {
    border: 1px solid #3c79e6;
    border-radius: 18.7423px;
    background-color: #EFF4FD;
  }
  .banner-plan-details:hover {
   
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
  }
  .banner-plan-details-1 {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    line-height: 18px;
    color: #3c79e6;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .banner-plan-details-2 {
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    color: #333333;
    line-height: 35px;
    padding-top: 10px;
    padding-bottom: 4px;
    margin-bottom: 0;
  }
  .banner-plan-details-3 {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    line-height: 23px;
    color: #727272;
    margin-bottom: 0;
    padding-bottom: 20px;
  }
  .plan {
    border-color: #3c79e6 !important;
  }
  .plan-type {
    background-color: #3c79e6;
    color: #fff;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }
  .plan-data {
    background-color: #EFF4FD;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
  }
  .plan-amount {
    color: #3c79e6;
  }
  .lable-size{
    font-size: 18px;
  }

  /* input[type="date"]::before {
    color: #999999 !important;
    content: attr(placeholder) !important;
  }
  input[type="date"] {
    color: #ffffff;
  }
  input[type="date"]:focus,
  input[type="date"]:valid {
    color: #666666;
  }
  input[type="date"]:focus::before,
  input[type="date"]:valid::before {
    content: "" !important;
  } */


  input[type="date"]:not(.has-value):before{
    color: #6e6e6e;
    content: attr(placeholder);
  }

  .react-tel-input{
    width: auto;
  }
  .react-tel-input .form-control{
    width: 110px !important;
    font-size: 14px !important;
    border-radius: 9px;
    border: 1px solid #f0f1f5;
    padding: 18.5px 9px 18.5px 52px;
  }
  .react-tel-input .country-list{
    width: 250px !important;
  }